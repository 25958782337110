import {Row, Col} from 'react-bootstrap'
import Logo2 from '../../assets/images/LogoNew2.png'
import {Text, Flex, Button, Stack, Box } from "@chakra-ui/react";
import LogoInstagram from '../../assets/images/icon-instragram.png'
import LogoFacebook from '../../assets/images/icon-facebook.png'
import LogoOpenseas from '../../assets/images/icon-opensea.png'
import LogoTwitter from '../../assets/images/icon-twitter.png'
import {BigNumber, ethers} from "ethers";
import MutantApe from "../../MutantApe.json";
import axios from 'axios';
import {RckcAddress} from "../../services/mutantFunctions";

function FooterPage ({accounts, setAccounts,isMember,setMember,isMemberWorstApe,setMemberWorstApe}) {
    return (
        <div className='container-md' >
               <Row>
                <Col lg={3} className='py-5 d-flex'>
                    <Col lg={5} className='py-5'>

                        <Flex display={{ base: 'none', md: 'flex' }} gap="20" mr={4}>
                            <a target='_blank' href="https://twitter.com/RareCorgiKC?fbclid=IwAR2am5Zy66dHngibnQJck-bHt2eulLXVbsQC_ku7v9JAO1zY7cgsb94x0K0">
                                <Button className='btn-circle'><img src={Logo2} width="80px"/></Button>
                            </a>
                            <a target='_blank' href="https://twitter.com/RareCorgiKC?fbclid=IwAR2am5Zy66dHngibnQJck-bHt2eulLXVbsQC_ku7v9JAO1zY7cgsb94x0K0">
                                <Button className='btn-circle'><img src={LogoTwitter} /></Button>
                            </a>
                            <a target='_blank' href="https://opensea.io/">
                                <Button className='btn-circle'><img src={LogoOpenseas} /></Button>
                            </a>
                        </Flex>
                    </Col>
                </Col>

               </Row>
        </div>

    );
}

export default FooterPage;