import "./App.css";
import "./assets/custom.css"
import MainMint from "./MainMint";
import NavbarHeader from "./components/layout/navbar_head";
import Footer from "./components/layout/footer";
import CarouselPage from "./components/pages/CarouselPage";
import AboutPage from "./components/pages/AboutPage";
import OurTeamPage from "./components/pages/OurTeamPage";
import OurProjectPage from "./components/pages/OurProjectsPage";
import CountdownTimer from "./CountdownTimer";
import MutantComponent from "./components/pages/MutantComponent";


import { useState } from "react";
import RoadMap from "./RoadMap";
import About from "./About";
import { Box, Flex, Text } from "@chakra-ui/react";
import Team from "./Team";
import MintPage from "./components/pages/MintPage";
import CollectionList from "./components/pages/CollectionsList";
import QAPage from "./components/pages/QAPage";


  const Main = ({accounts, setAccounts,isMember,setMember,isMemberWorstApe,setMemberWorstApe}) => {
    const isConnected =  Boolean(accounts[0]);


   
  const dateTimeAfterThreeDays = 1670562000000;
  console.log(dateTimeAfterThreeDays)
  return (
    <div>
      <div className="overlay">
        <div className="App">

        {/*<div id="home" style={{marginTop: "110px"}}>*/}
        {/*  <CountdownTimer targetDate={dateTimeAfterThreeDays}/>*/}
        {/*</div>*/}
        <div style={{marginTop: "5px"}}>
          <MintPage
              accounts={accounts}
              setAccounts={setAccounts}
              isMember={isMember}
              setMember={setMember}
              isMemberWorstApe={isMemberWorstApe}
              setMemberWorstApe={setMemberWorstApe}
          />
        </div>
        {/*<div className="py-5" style={{marginTop: "10px"}}>*/}
        {/*  <MutantComponent />*/}
        {/*  /!* <QAPage /> *!/*/}
        {/*  <div class="bg-head"></div>*/}
        {/*  <div class="bg-collect">*/}
        {/*  <CollectionList  />*/}
        {/*  </div>*/}
        {/*</div>*/}
          {/*<div style={{marginTop: "40px"}}>*/}
          {/*  <CarouselPage />*/}
          {/*</div>*/}
        {/*<div id="about" style={{marginTop: "150px"}}>*/}
        {/*  <AboutPage />*/}
        {/*</div>*/}
        {/*<div id="team" style={{marginTop: "64px"}}>*/}
        {/*  <OurTeamPage />*/}
        {/*</div>*/}
        {/*<div id="roadMap" style={{marginTop: "124px"}}>*/}
        {/*    <OurProjectPage />*/}
        {/*</div>*/}
          {/* <MainMint
            accounts={accounts}
            setAccounts={setAccounts}
            isMember={isMember}
            setMember={setMember}
            isMemberWorstApe={isMemberWorstApe}
            setMemberWorstApe={setMemberWorstApe}
          /> */}
        </div>
       
      </div>
      
    </div>
  );
}
export default Main;
