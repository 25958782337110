import {Box, Button, Flex, Text} from '@chakra-ui/react'
import { Container } from 'react-bootstrap'
import {BigNumber, ethers} from "ethers";
import MySwal from "sweetalert2";
import NavBar, {contract, wallet_Address,connectAccount} from "../layout/navbar_head";
import {useEffect, useState,useMemo} from "react";

import dog1 from "../../assets/dogs/1.png";
import dog2 from "../../assets/dogs/2.png";
import dog3 from "../../assets/dogs/3.png";
import dog4 from "../../assets/dogs/4.png";
import dog5 from "../../assets/dogs/5.png";
import dog6 from "../../assets/dogs/6.png";
import dog7 from "../../assets/dogs/7.png";
import dog8 from "../../assets/dogs/8.png";
import dog9 from "../../assets/dogs/9.png";
import dog10 from "../../assets/dogs/10.png";
import dog11 from "../../assets/dogs/11.png";
import dog12 from "../../assets/dogs/12.png";
import dog13 from "../../assets/dogs/13.png";
import dog14 from "../../assets/dogs/14.png";
import dog15 from "../../assets/dogs/15.png";
import dog16 from "../../assets/dogs/16.png";
import dog17 from "../../assets/dogs/18.png";
import dog18 from "../../assets/dogs/18.png";
import dog19 from "../../assets/dogs/19.png";
import dog20 from "../../assets/dogs/20.png";
import dog21 from "../../assets/dogs/21.png";
import dog22 from "../../assets/dogs/22.png";
import dog23 from "../../assets/dogs/23.png";
import dog24 from "../../assets/dogs/24.png";
import dog25 from "../../assets/dogs/25.png";
import dog26 from "../../assets/dogs/26.png";
import dog27 from "../../assets/dogs/27.png";
import dog28 from "../../assets/dogs/28.png";
import dog29 from "../../assets/dogs/29.png";
import dog30 from "../../assets/dogs/30.png";
import LogoTeam from '../../assets/images/logoteam.png'

import ImageGallery from "../../services/ImageGallery";
import CountdownTimer from "../../CountdownTimer";

function MintPage ({accounts, setAccounts,isMember,setMember,isMemberWorstApe,setMemberWorstApe}) {
    const isConnected =  Boolean(accounts[0]);

    // const [selectCard, setSelectCard] = useState();
    //
    // const [list, setList] = useState([]);
    //
    // const [totalNFT, settotalNFT] = useState(0);
    //
    // const nft = useMemo(() => getNFT(), [totalNFT]);
    // const getNFT = async () => {
    //
    //     return 1;
    //
    // };
    const [message, setMessage] = useState('');
    const [isFree, setFree] = useState(true);
    const [mintAmount, setMintAmount] = useState(1);


    // setInterval(async () => {
    //
    //     const totalMint = await contract._lv1();
    //     setMessage(`Total Mint: ${totalMint-20}/4435`);
    //     if(totalMint >= 320){
    //         setFree(false);
    //     }
    //     setMessage(`4455`);
    //
    // }, 1000);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         fetchGraphData();
    //     },1000);
    //     return () => clearInterval(interval);
    // }, []);
    // const fetchGraphData = async () => {
    //         const totalMint = await contract._lv1();
    //         setMessage(`${totalMint}/4455`);
    //         // setMessage(`4455`);
    //
    // };

    async function mint() {
            try {
                const mintPrice = await contract.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contract.mint(BigNumber.from(mintAmount),{
                    value: ethers.utils.parseEther((cost * mintAmount).toString()),
                });
                console.log("response: ", response);
            } catch (err) {
                console.log(err)
                console.log("error1: ", err.reason);
                await MySwal.fire({
                    html: err.reason,
                    icon: 'error',
                    type: "error",
                    iconColor: 'black',
                    confirmButtonColor: 'black'
                });
            }
    }


    const handleDerrement = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    };

    const handleIncerment = () => {
        if (mintAmount >= 20) return;
        setMintAmount(mintAmount + 1);
    };

    const images = [
        { src: dog1, alt: 'Image 1' },
        { src: dog2, alt: 'Image 2' },
        { src: dog3, alt: 'Image 3' },
        { src: dog4, alt: 'Image 4' },
        { src: dog5, alt: 'Image 5' },
        { src: dog6, alt: 'Image 6' },
        { src: dog7, alt: 'Image 7' },
        { src: dog8, alt: 'Image 8' },
        { src: dog9, alt: 'Image 9' },
        { src: dog10, alt: 'Image 10' },
        { src: dog11, alt: 'Image 11' },
        { src: dog12, alt: 'Image 12' },
        { src: dog13, alt: 'Image 13' },
        { src: dog14, alt: 'Image 14' },
        { src: dog15, alt: 'Image 15' },
        { src: dog16, alt: 'Image 16' },
        { src: dog17, alt: 'Image 2' },
        { src: dog18, alt: 'Image 3' },
        { src: dog19, alt: 'Image 4' },
        { src: dog20, alt: 'Image 5' },
        { src: dog21, alt: 'Image 6' },
        { src: dog22, alt: 'Image 7' },
        { src: dog23, alt: 'Image 8' },
        { src: dog24, alt: 'Image 9' },
        { src: dog25, alt: 'Image 10' },
        { src: dog26, alt: 'Image 11' },
        { src: dog27, alt: 'Image 12' },
        { src: dog28, alt: 'Image 13' },
        { src: dog29, alt: 'Image 14' },
        { src: dog30, alt: 'Image 15' },
    ];

    return(
        <>
        {/*<div className='container d-none d-lg-block' style={{marginTop: '64px'}}>*/}
        {/*    <Grid templateColumns='repeat(3, 1fr)' gap={6}>*/}

                {/*<Card className='px-4 py-5 border-mint-card' style={{backgroundColor:'#1D1D1D', border: '1px solid #DCB839'}}>*/}
                {/*    <Card.Title className='d-flex align-middle my-1'>*/}
                {/*        <img className='mt-2 mx-2' width='40px' style={{height: '40px'}} src={IconMutant} />*/}
                {/*        <Text fontSize='5xl'>Free Mint</Text>*/}
                {/*    </Card.Title>*/}
                {/*    <Card.Body className='text-start'>*/}
                {/*        <Text fontSize='2xl' >RELEASE 300 TOKENS</Text>*/}
                {/*        /!*<Text color='#DCB839' fontSize='2xl'>0.007 ETH</Text>*!/*/}
                {/*        /!*<Text color='#1D1D1D' fontSize='2xl'>s</Text> <pre/>*!/*/}
                {/*    </Card.Body>*/}
                {/*</Card>*/}


                {/*<Card className='px-4 py-5 border-mint-card' style={{backgroundColor:'#1D1D1D', border: '1px solid #DCB839'}}>*/}
                {/*    <Card.Title className='d-flex align-middle my-1'>*/}
                {/*        <img className='mt-2 mx-2' width='40px' style={{height: '40px'}} src={IconMutant} />*/}
                {/*        <Text fontSize='5xl'>Mint</Text>*/}
                {/*    </Card.Title>*/}
                {/*    <Card.Body className='text-start'>*/}
                {/*        <Text fontSize='2xl' >RELEASE 4135 TOKENS</Text>*/}
                {/*        /!*<Text color='#DCB839' fontSize='2xl'>0.007 ETH</Text>*!/*/}
                {/*        /!*<Text color='#1D1D1D' fontSize='2xl'>s</Text> <pre/>*!/*/}
                {/*    </Card.Body>*/}
                {/*</Card>*/}

                {/*<Card className='px-4 py-5 border-mint-card' style={{backgroundColor:'#1D1D1D', border: '1px solid #DCB839'}}>*/}
                {/*<Card.Title className='d-flex align-middle'>*/}
                {/*        <img className='mt-2 mx-2' width='40px' style={{height: '40px'}} src={IconMutant} />*/}
                {/*        <Text fontSize='5xl' color='#DCB839'>Mutant</Text>*/}
                {/*    </Card.Title>*/}
                {/*    <Card.Body className='text-start'>*/}
                {/*        <Text fontSize='2xl'>RELEASE 1100 TOKENS</Text> <pre/>*/}
                {/*        <Text color='#DCB839' fontSize='2xl'>FREE</Text>*/}

                {/*    </Card.Body>*/}
                {/*</Card>*/}



        {/*    </Grid>*/}

            <Container>
                <Box>
                    <div>
                        <Text fontSize="80px" color="#7CFC00" fontFamily={"Khand"} textshawdow="0 5px #000000" style={{marginTop: '10px'}}>
                            Rare Corgi Kennel Club
                        </Text>

                        <Text
                            fontSize="27px"
                            fontFamily={"VT323"}
                            textshawdow={"0 5px #000000"}
                        >
                            This year, we are releasing 5,555 Corgis for adoption to celebrate the new year 2023. The cutest living creature on the Ethereum blockchain. They're adorable little dogs with big, furry trunks and tiny, stubby legs.
                        </Text>
                        <p style={{marginTop: '20px'}}>
                            <ImageGallery images={images} />
                        </p>



                    </div>
                </Box>
            </Container>
            {/*countdown*/}
            {/*<CountdownTimer*/}
            {/*  accounts={accounts}*/}
            {/*  setAccounts={setAccounts}*/}
            {/*  targetDate={1674172800000}*/}
            {/*/>*/}
            {isConnected ? (  <div style={{marginTop: '25px'}}>
                <Flex align="center" justifyContent="center">
                    <Button
                        backgroundColor="#5C5C5C"
                        borderRadius="5px"
                        boxShadow="0px 2px 2px 1px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="10px"
                        // marginTop="10px"
                        fontWeight="bold"
                        fontSize="15px"
                        onClick={handleDerrement}
                    >
                        -
                    </Button>
                    <Text
                        color="#7CFC00"
                        fontWeight="bold"
                        fontSize="20px"
                        paddingTop="0px"
                        fontFamily="VT323"
                        paddingRight={"40px"}
                        paddingLeft={"40px"}
                        textshawdow="0 5px #000000"
                    >
                        {mintAmount}
                    </Text>
                    <Button
                        backgroundColor="#5C5C5C"
                        borderRadius="5px"
                        boxShadow="0px 2px 2px 1px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="10px"
                        // marginTop="10px"
                        fontWeight="bold"
                        fontSize="15px"
                        onClick={handleIncerment}
                    >
                        +
                    </Button>
                </Flex>
                <Button
                    backgroundColor="#008000"
                    borderRadius="5px"
                    boxShadow="0px 2px 2px 1px #0F0F0F"
                    // fontWeight={"bold"}
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="15px"
                    marginTop="20px"
                    onClick={

                        mint
                    }
                >
                    Mint
                </Button>
            </div>):(<div>
                <Text className="flashing" fontSize="32px"  fontFamily={"VT323"} textshawdow="0 5px #000000" style={{marginTop: '20px'}}>
                   PLEASE CONNECT WALLET
                </Text>
            </div>) }


            <div style={{marginTop: '80px'}}>
                <Text  fontSize="30px" color="#7CFC00" fontFamily={"Khand"} textshawdow="0 5px #000000" style={{marginTop: '10px'}}>
                    Power by @2023 DREAMERS TEAM
                </Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center'  }}>
                <img src={LogoTeam} style={{ width: '250px' }} />
            </div>
        {/*</div>*/}
        {/*<div className='d-lg-none d-sm-block container' style={{marginTop: '64px'}}>*/}

        {/*    <Card className='px-4 py-5 border-mint-card' style={{backgroundColor:'#1D1D1D', border: '1px solid #DCB839'}}>*/}
        {/*        <Card.Title className='d-flex align-middle my-1'>*/}
        {/*            <img className='mt-2 mx-2' width='40px' style={{height: '40px'}} src={IconMutant} />*/}
        {/*            <Text fontSize='5xl'>Mint</Text>*/}
        {/*        </Card.Title>*/}
        {/*        <Card.Body className='text-start'>*/}
        {/*            <Text fontSize='2xl' >RELEASE 4135 TOKENS</Text>*/}
        {/*            /!*<Text color='#DCB839' fontSize='2xl'>0.007 ETH</Text>*!/*/}
        {/*            /!*<Text color='#1D1D1D' fontSize='2xl'>s</Text> <pre/>*!/*/}
        {/*        </Card.Body>*/}
        {/*    </Card>*/}
        {/*</div>*/}
        </>

    )
}

export default MintPage;