import { Spacer, Text } from "@chakra-ui/react"
import { Card, Button, Row, Col } from "react-bootstrap"

import iconEth from '../../assets/images/iconEth.png'
import { DeleteIcon } from '@chakra-ui/icons'


 export default function CardWorst ({dataImg, idx}) {
    console.log(dataImg)
    console.log(idx)
    console.log(idx)
    return (
        <div className="card-worst text-start my-2">
            <Card bg="dark" text='white'>
                <Card.Header className="d-flex align-items-center mt-2">
                    <img className="mr-2" src={dataImg.logo} width="30" />
                    <Text style={{marginLeft: "10px"}} className="pl-2">{dataImg.title}</Text>
                </Card.Header>
                <Card.Img  src={dataImg.Img} className="p-3"  />
                <Card.Body>
                    <Card.Title># {dataImg.tagId}</Card.Title>
                    <Card.Text className="d-flex align-items-center"> 
                        <Text color={"#A6A6A6"} fontSize={"11px"}>Last sale</Text>
                        <Text style={{marginLeft: "10px"}} fontSize={"smaller"}>{dataImg.lastSale} ETH</Text>
                    </Card.Text>
                    <Card.Text className="mt-4">
                        <Row>
                            <Col md="2"><img src={iconEth} /> </Col>
                            <Col md="3">
                                <Text color={"#A6A6A6"} fontSize={"smaller"}>Price</Text>
                                <Text fontSize={"md"}>{dataImg.price} ETH</Text>
                            </Col>
                            <Col><Button className="form-control "  variant="warning">View on Opensea</Button></Col>
                        </Row>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export const CardWMutant = ({dataImg, idx, isBlur, isId}) => {
    // console.log(dataImg)
    // console.log(isId)
    // console.log(isBlur ? "blur-card" : "")
    // console.log(isBlur , dataImg)
    return (
        <div className={`card-worst text-start my-2 " ${isBlur ? "blur-card" : ""} `} >

            <Card bg="dark" className={`${isId != undefined ? "hover-to-cancel" : ""}`}  text='white'>
                <Card.Header className="d-flex align-items-center mt-2">
                    <img className="mr-2" src={dataImg.logo} width="30" />
                    <Text style={{marginLeft: "10px"}} className="pl-2">{dataImg.title}</Text>
                </Card.Header>
                <Card.Img  src={dataImg.Img} className="p-3"  />
                <Card.Body>
                    <Card.Title># {dataImg.tagId}</Card.Title>

                </Card.Body>
                <div class="middle">
                    <div class="text"><DeleteIcon boxSize={8} /></div>
                </div>
            </Card>
        </div>
    )
}
