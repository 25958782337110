import { useState, useEffect } from 'react';

function ImageGallery(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const images = props.images;

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImage((current) => (current + 1) % images.length);
        }, 2000);
        return () => clearInterval(timer);
    }, [images]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={images[currentImage].src} alt={images[currentImage].alt} style={{ width: '350px' }} />
        </div>
    );
}



export default ImageGallery;