import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from "@chakra-ui/react";
import { Card, Container } from "react-bootstrap";
import { ChevronDownIcon, ChevronRightIcon  } from "@chakra-ui/icons"

function QAPage() {
  var dataArray = [];
  var obj = [
    {
      question: "1. Q: Why do we need 1,000 ape ?",
      answer:
        "Because either Worst Pill or Worst Side require 1,000 apes for each project, it is crucial to have enough apes for mutations.",
      ps: "P.S. Usual Mutant Formula",
      description: "1 WA + 1 WP = 1 WMA or 1 WA + 1 WS = 1 WMA",
    },
    {
      question: "2. Q: Why does Worst Ape need to be burned ?",
      answer:
        "A: Worst Side and Worst Pill, as we are all aware, have more NFTs than Worst Ape twice, which implies that one Ape must mutate twice, which will result in confusion and turmoil for holders.",
      description: "Thus, we believe that burning an ape is justified",
    },
    {
      question: "3. Q: What is “Gas War” ?",
      answer:
        "A: It is how apes are personally added and sold on Opensea. We would claim to be the Worst Ape on Twitter before selling them.",
      pa: "P.S. By adopting Gas War in the beginning, Worst Ape attracted a lot of attention and positive response from our community.",
    },
  ];

  for (let index = 0; index < obj.length; index++) {
    dataArray.push(
      <Accordion defaultIndex={[0]} allowMultiple>
        <Card bg="dark" className="my-3" style={{borderRadius: 0}}>
          <AccordionItem>
          {({ isExpanded }) => (
            <>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontSize={{ base: "md", sm: "md", md: "3xl"}}>{obj[index].question}</Text>
              </Box>
              {isExpanded ? (
              <ChevronDownIcon color="#DCB839"  />
            ) : (
              <ChevronRightIcon color="#DCB839" />
            )}
              {/* <AccordionIcon /> */}
            </AccordionButton>

            <AccordionPanel color="#D9D9D9" textAlign="left" my={4} pb={4}>
              {obj[index].answer}
              {obj[index].ps ? (
                <div className="mt-5">
                  <div className="mt-3">{obj[index].ps}</div>
                  <div className="mt-3">{obj[index].description}</div>
                </div>
              ) : (
                ""
              )}
            </AccordionPanel>
            </>
            )}
          </AccordionItem>
        </Card>
      </Accordion>
    );
  }
  return (
    <>
      <Container>{dataArray}</Container>
    </>
  );
}

export default QAPage;
