import {BigNumber, ethers} from "ethers";


import MySwal from "sweetalert2";
import {contract, wallet_Address} from "../components/layout/navbar_head";
import axios from "axios";


export const RckcAddress = "0x32a1441654ECB28993B81b8C279eD86d457370Fc";


async function handleMint(mintAmount) {
            try {
                const mintPrice = await contract.cost();
                const cost = Number(mintPrice.toString()) / (1000000000000000000);
                const response = await contract.mint(BigNumber.from(mintAmount), {
                    value: ethers.utils.parseEther((cost * mintAmount).toString()),
                });
                console.log("response: ", response);
            } catch (err) {
                console.log("error1: ", err.reason);
                MySwal.fire({
                    title: <strong>Error</strong>,
                    html: err.reason,
                    icon: 'error',
                    type: "error",
                    iconColor: 'black',
                    confirmButtonColor: 'black'
                });
            }

    }


